import React, { Component } from "react";
import "./onCallMain.scss";
import {
  ButtonGroup,
  Tabs,
  Tab,
  Button,
  ButtonToolbar,
  Modal
} from "react-bootstrap";
import HospitalServices from "../hospitalServices/hospitalServices";
import PediatricServices from "../pediatricServices/pediatricServices";
import PediatricServicesWard from "../pediatricServicesWard/pediatricServicesWard";
import ShrinerService from "../shrinerService/shrinerService"
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";
// import DateTimePicker from "react-datetime-picker";
import { configuration } from "../../environment/environment";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { withAuth } from "@okta/okta-react";
import { createBrowserHistory } from "history";
import Datetime from "react-datetime";
const history = createBrowserHistory({ basename: "" });

const DOMAIN = configuration;
const HOSPITAL_SERVICE = "hospitalservice";
const PEDIATRIC_SERVICE = "pediatricservice";
const PEDIATRIC_WARD_SERVICE = "pediatricwardservice";
const SHRINER_SERVICE = "shrinerservice";
const HOSPITAL_SERVICE_URL = `${DOMAIN}/api/HospitalService`;
const PEDIATRIC_SERVICE_URL = `${DOMAIN}/api/PediatricHouseStaff`;
const PEDIATRIC_WARD_SERVICE_URL = `${DOMAIN}/api/WardAttending/schedule`;
const SHRINER_SERVICE_URL = `${DOMAIN}/api/shriners`;
const RIGHT_NOW = "rightnow";
const TWENTYFOUR_HOUR = "24hour";
const CUSTOM_NOW = "custom";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default
  withAuth(
    class OnCallMain extends Component {
      constructor(props, context) {
        super(props, context);
        this.state = {
          currentView: HOSPITAL_SERVICE,
          currentURL: HOSPITAL_SERVICE_URL,
          error: null,
          isLoaded: false,
          isTabLoaded: false,
          data: {},
          currentButton: RIGHT_NOW,
          servicesData: [],
          serviceDataPediatric: [],
          servicesDataWard: [],
          shrinerData: [],
          extractDate: "",
          selectedDay: undefined,
          value: this.props.initialValue,
          date: new Date(),
          modalShow: false,
          show: false,
          startDate: "",
          currentDay: "",
          currentMonth: "",
          open: false,
          calendarClick: false
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleButtonChange = this.handleButtonChange.bind(this);
        this.callRightNow = this.callRightNow.bind(this);
      }

      onChange = date => this.setState({ date: date._d });

      componentDidMount() {
        this.callRightNow();
        setInterval(this.callRightNow, 300000);
      }

      calendarClick = () => {
        this._calendar.setOpen(true);
      };

      // FETCHING DATA
      getData = async (startPeriod, interval, url) => {
        let servicesData, extractDate;
        url = url + "?startPeriod=" + startPeriod + "&interval=" + interval;
        let authorizationToken =
          "Bearer " + (await this.props.auth.getAccessToken());
        this.props.auth._oktaAuth.tokenManager
          .get("accessToken")
          .then(token => {
            if (token) {
            } else {
              this.props.auth.login("/");
            }
          })
          .catch(function(err) {
            // OAuth Error
          });

        axios
          .get(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Pragma: "no-cache",
              Authorization: authorizationToken
            }
          })
          .then(result => {
            servicesData = result.data.services;
            extractDate = result.data.lastExtract;
            if (this.state.currentURL === HOSPITAL_SERVICE_URL) {
              this.setState({
                servicesData: servicesData,
                extractDate: extractDate,
                isLoaded: true,
                isTabLoaded: true
              });
            }
          })
          .catch(err => {
            this.setState({
              isLoaded: false,
              error: err
            });
          });
      };

      //get pediatric data
      getDataPediatric = async (startPeriod, interval, url) => {
        let servicesData, extractDate;
        url = url + "?startPeriod=" + startPeriod + "&interval=" + interval;
        let authorizationToken =
          "Bearer " + (await this.props.auth.getAccessToken());

        this.props.auth._oktaAuth.tokenManager
          .get("accessToken")
          .then(token => {
            if (token) {
            } else {
              this.props.auth.login("/");
            }
          })
          .catch(function(err) {});

        axios
          .get(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Pragma: "no-cache",
              Authorization: authorizationToken
            }
          })
          .then(result => {
            servicesData = result.data.services;
            extractDate = result.data.lastExtract;
            if (this.state.currentURL === PEDIATRIC_SERVICE_URL) {
              this.setState({
                serviceDataPediatric: servicesData,
                extractDate: extractDate,
                isLoaded: true,
                isTabLoaded: true
              });
            }
          })
          .catch(err => {
            this.setState({
              isLoaded: false,
              error: err
            });
          });
      };

      //get Pediatric ward data
      getPediatricWardData = async () => {
        let servicesData;
        let url = PEDIATRIC_WARD_SERVICE_URL;
        let authorizationToken =
          "Bearer " + (await this.props.auth.getAccessToken());
        this.props.auth._oktaAuth.tokenManager
          .get("accessToken")
          .then(token => {
            if (token) {
            } else {
              this.props.auth.login("/");
            }
          })
          .catch(function(err) {
            // OAuth Error
          });
        axios
          .get(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Pragma: "no-cache",
              Authorization: authorizationToken
            }
          })
          .then(result => {
            servicesData = result.data;
            if (this.state.currentURL === PEDIATRIC_WARD_SERVICE_URL) {
              this.setState({
                servicesDataWard: servicesData,
                isTabLoaded: true,
                isLoaded: true
              });
            }
          })
          .catch(err => {
            this.setState({
              isLoaded: false,
              error: err
            });
          });
      };


      // Shriner's 
            getShrinersData = async () => {
              let servicesData;
              let url = SHRINER_SERVICE_URL;
              let authorizationToken =
                "Bearer " + (await this.props.auth.getAccessToken());
              this.props.auth._oktaAuth.tokenManager
                .get("accessToken")
                .then(token => {
                  if (token) {
                  } else {
                    this.props.auth.login("/");
                  }
                })
                .catch(function(err) {
                  // OAuth Error
                });
              axios
                .get(url, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Pragma: "no-cache",
                    Authorization: authorizationToken
                  }
                })
                .then(result => {
                  servicesData = result.data.services;
                  if (this.state.currentURL === SHRINER_SERVICE_URL) {
                    this.setState({
                      shrinerData: servicesData,
                      isTabLoaded: true,
                      isLoaded: true
                    });
                  }
                })
                .catch(err => {
                  this.setState({
                    isLoaded: false,
                    error: err
                  });
                });
            };

      // on Click on the tabs calling this method
      servicecalled(service) {
        if (service === HOSPITAL_SERVICE) {
          this.setState(
            {
              currentView: HOSPITAL_SERVICE,
              currentURL: HOSPITAL_SERVICE_URL
            },
            () => {
              this.callRightNow();
            }
          );
        } else if (service === PEDIATRIC_SERVICE) {
          this.setState(
            {
              currentView: PEDIATRIC_SERVICE,
              currentURL: PEDIATRIC_SERVICE_URL
            },
            () => {
              this.callRightNow();
            }
          );
        } else if (service === PEDIATRIC_WARD_SERVICE) {
          this.setState(
            {
              currentView: PEDIATRIC_WARD_SERVICE,
              currentURL: PEDIATRIC_WARD_SERVICE_URL
            },
            () => {
              this.callRightNow();
            }
          );
        }
        else if(service === SHRINER_SERVICE) {
          this.setState(
            {
              currentView: SHRINER_SERVICE,
              currentURL: SHRINER_SERVICE_URL
            },
            () => {
              this.callRightNow();
            }
          )
        }
      }

      handleButtonChange = () => {
        this.setState({ modalShow: true });
      };
      handleButtonClose = () => {
        this.setState({ modalShow: false });
      };

      handleClose = () => {
        this.setState({ show: false });
      };
      handleShow = () => {
        this.setState({ show: true });
      };

      // On click on Right now button functionality
      callRightNow() {
        let interval = 1;
        let days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ];
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        let currentTime = new Date();
        let currentFullDay = days[currentTime.getDay()];
        let currentFullMonth = months[currentTime.getMonth()];

        let splitDate = currentTime.toISOString().split("T")[0];
        let splitTime = currentTime.toISOString().split("T")[1];
        let splitHr = splitTime.split(":")[0];
        let splitMin = splitTime.split(":")[1];
        let UTCTime = splitDate + "T" + splitHr + ":" + splitMin;

        let currentTimeSlice =
          currentFullDay.toString() +
          ", " +
          currentFullMonth.toString() +
          " " +
          currentTime.getDate() +
          ", " +
          currentTime.getFullYear() +
          " " +
          currentTime.toLocaleTimeString().split("GMT")[0];
        let startPeriod = UTCTime;
       
        this.setState(
          {
            startDate: currentTimeSlice,
            currentDay: currentFullDay,
            currentMonth: currentFullMonth,
            currentButton: RIGHT_NOW,
            date: new Date(),
            servicesData: [],
            serviceDataPediatric: [],
            servicesDataWard: [],
            shrinerData: [],
            isTabLoaded: false,
            isLoaded: false,
            error: null
          },
          () => {
            if (this.state.currentURL === HOSPITAL_SERVICE_URL) {
              this.getData(startPeriod, interval, this.state.currentURL);
            } else if (this.state.currentURL === PEDIATRIC_SERVICE_URL) {
              this.getDataPediatric(
                startPeriod,
                interval,
                this.state.currentURL
              );
            } else if (this.state.currentURL === PEDIATRIC_WARD_SERVICE_URL) {
              this.getPediatricWardData();
            }
            else if(this.state.currentURL === SHRINER_SERVICE_URL) {
              this.getShrinersData();
            }
          }
        );
      }

      // On click on 24hrs button functionality
      CallTwentyFourHour() {
        localStorage.removeItem('clickState');
        let days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ];
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        let interval = 2;
        let currentTime = new Date();
        let currentFullDay = days[currentTime.getDay()];
        let currentFullMonth = months[currentTime.getMonth()];

        let splitDate = currentTime.toISOString().split("T")[0];
        let splitTime = currentTime.toISOString().split("T")[1];
        let splitHr = splitTime.split(":")[0];
        let splitMin = splitTime.split(":")[1];
        let UTCTime = splitDate + "T" + splitHr + ":" + splitMin;
        let currentTimeSlice =
          currentFullDay.toString() +
          ", " +
          currentFullMonth.toString() +
          " " +
          currentTime.getDate() +
          ", " +
          currentTime.getFullYear() +
          " " +
          currentTime.toLocaleTimeString().split("GMT")[0];


        let startPeriod = UTCTime;
        this.setState(
          {
            startDate: currentTimeSlice,
            currentDay: currentFullDay,
            currentMonth: currentFullMonth,
            currentButton: TWENTYFOUR_HOUR,
            date: new Date(),
            servicesData: [],
            serviceDataPediatric: [],
            servicesDataWard: [],
            shrinerData: [],
            isTabLoaded: false,
            isLoaded: false,
            error: null
          },
          () => {
            if (this.state.currentURL === HOSPITAL_SERVICE_URL) {
              this.getData(startPeriod, interval, this.state.currentURL);
            } else if (this.state.currentURL === PEDIATRIC_SERVICE_URL) {
              this.getDataPediatric(
                startPeriod,
                interval,
                this.state.currentURL
              );
            } else if (this.state.currentURL === PEDIATRIC_WARD_SERVICE_URL) {
              this.getPediatricWardData();
            }
            else if(this.state.currentURL === SHRINER_SERVICE_URL) {
              this.getShrinersData();
            }
          }
        );
      }

      // on Click on custom calendar functionality
      customDateTime() {
        localStorage.removeItem('clickState');
        let days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ];
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
       


        let interval = 2;
        let currentDate = this.state.date;
        let currentFullDay = days[currentDate.getDay()];
        let currentFullMonth = months[currentDate.getMonth()];

        let splitDate = currentDate.toISOString().split("T")[0];
        let splitTime = currentDate.toISOString().split("T")[1];
        let splitHr = splitTime.split(":")[0];
        let splitMin = splitTime.split(":")[1];
        let UTCTime = splitDate + "T" + splitHr + ":" + splitMin;
        let currentTimeSlice =
          currentFullDay.toString() +
          ", " +
          currentFullMonth.toString() +
          "" +
          currentDate.getDate() +
          ", " +
          currentDate.getFullYear() +
          " " +
          currentDate.toLocaleTimeString().split("GMT")[0];
        this.setState({ currentButton: CUSTOM_NOW });
        if (currentDate) {
          let startPeriod = UTCTime;
          this.setState(
            {
              startDate: currentTimeSlice,
              servicesData: [],
              serviceDataPediatric: [],
              servicesDataWard: [],
              shrinerData: [],
              isTabLoaded: false,
              isLoaded: false,
              error: null
            },
            () => {
              if (this.state.currentURL === HOSPITAL_SERVICE_URL) {
                this.getData(startPeriod, interval, this.state.currentURL);
              } else if (this.state.currentURL === PEDIATRIC_SERVICE_URL) {
                this.getDataPediatric(
                  startPeriod,
                  interval,
                  this.state.currentURL
                );
              } else if (this.state.currentURL === PEDIATRIC_WARD_SERVICE_URL) {
                this.getPediatricWardData();
              }
              else if (this.state.currentURL === SHRINER_SERVICE_URL) {
                this.getShrinersData();
              }
            }
          );
        } else {
          this.handleShow();
        }
      }

      render() {
        let i = <i className="fa fa-calendar calender" aria-hidden="true" />;
        let popUp = (
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.modalShow}
            onHide={this.handleButtonClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter title"
                style={{ color: "#015a96" }}
              >
                Notice
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: "grey" }}>
                Since schedule data may be updated throughout the day, printing
                this schedule is discouraged.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleButtonClose} className="print_inner">
                Close
              </Button>

              <ReactToPrint
                trigger={() => <Button className="print_inner">Print</Button>}
                content={() => this.componentRef}
              />
            </Modal.Footer>
          </Modal>
        );
        const { error } = this.state;
        let currentService, errorInfo;
        let serviceNoteSchedule;
        if (this.state.currentButton === RIGHT_NOW) {
          serviceNoteSchedule = (
            <h4 className="menuOptionHeading">
              Schedule for {this.state.startDate}
            </h4>
          );
        } else if (this.state.currentButton === TWENTYFOUR_HOUR) {
          serviceNoteSchedule = (
            <h4 className="menuOptionHeading">
              24-hour schedule for {this.state.startDate}
            </h4>
          );
        } else if (this.state.currentButton === CUSTOM_NOW) {
          let splitDate = this.state.startDate.split(",");
          let day = splitDate[0];
          let date = splitDate[1]
            .replace(/'/g, "")
            .split(/(\d+)/)
            .filter(Boolean);
          let dateString = date[0] + " " + date[1];
          let time = splitDate[2];
          let wholeSorteDateTime = day + "," + dateString + "," + time;
          serviceNoteSchedule = (
            <h4 className="menuOptionHeading">
              24-hour schedule for {wholeSorteDateTime}
            </h4>
          );
        }

        if (error) {
          currentService = (
            <div className="error_detail">
              No Schedule found...Please try again later
            </div>
          );
        } else {
          if (!this.state.isLoaded || !this.state.isTabLoaded) {
            currentService = (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10%",
                  marginBottom: "10%"
                }}
              >
                <BeatLoader
                  css={override}
                  sizeUnit={"px"}
                  size={15}
                  color={"#00B4E8"}
                  loading={!this.state.isLoaded || !this.state.isTabLoaded}
                />
              </div>
            );
          } else if (this.state.currentView === HOSPITAL_SERVICE) {
            currentService = (
              <HospitalServices
                hospitalServicesData={this.state.servicesData}
                extractDate={this.state.extractDate}
              />
            );
          } else if (this.state.currentView === PEDIATRIC_SERVICE) {
            currentService = (
              <PediatricServices
                pediatricServicesData={this.state.serviceDataPediatric}
                extractDate={this.state.extractDate}
              />
            );
          } else if (this.state.currentView === PEDIATRIC_WARD_SERVICE) {
            currentService = (
              <PediatricServicesWard
                pediatricServicesWardData={this.state.servicesDataWard}
              />
            );
          }
          else if (this.state.currentView === SHRINER_SERVICE) {
            currentService = (
              <ShrinerService shrinerServiceData={this.state.shrinerData} shrinerservice={this.state.SHRINER_SERVICE} />
            )
          }
        }

        return (
          <div>
            <div className="container" id="printme">
              <br />
              <div className="menuOptions">
                <div className="viewSchedule">
                  <h6 id="view_sch">View today's schedule</h6>
                  <div id="para_text">
                    <ButtonToolbar>
                      <Button
                        className="btn-sm topBtn "
                        variant="success"
                        size="sm"
                        onClick={() => this.callRightNow()}
                      >
                        Right Now
                        <i id="recycle" class="fa fa-recycle"></i>
                      </Button>
                      {/* <a href="#" onClick={(e) => this.refreshData(e)}><i id="recycle" class="fa fa-recycle"></i></a> */}
                      <Button
                        className="btn-sm topBtn btn2 "
                        variant="success"
                        size="sm"
                        onClick={() => this.CallTwentyFourHour()}
                      >
                        24 Hours
                      </Button>
                    </ButtonToolbar>
                  </div>
                </div>
                <div className="viewScheduleDateTime">
                  <h6 id="view_schedule">
                    View Schedule for a different date and time
                  </h6>
                  <div>
                    <label className="label_datetime">
                      <Datetime
                        value={this.state.date}
                        onChange={this.onChange}
                        dateFormat="MM / DD / YYYY  "
                        timeFormat={true}
                        preventOpenOnFocus={true}
                        input={true}
                        closeOnSelect={false}
                      />
                      <i
                        className="fa fa-calendar calendar"
                        aria-hidden="true"
                      ></i>
                    </label>
                    <ButtonGroup className="btn_group submit">
                      <Button
                        className="btn-sm topBtn btn2 btntoolbar "
                        onClick={() => this.customDateTime()}
                        size="sm"
                        variant="success"
                      >
                        Submit
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
              <div>
                {serviceNoteSchedule}
                <div className="mt-2"></div>
              </div>
              <span className="space_bar"> &nbsp;</span>

              <div />
              <div>
                <div>
                  <a className="print">
                    <i
                      className="fa fa-print print"
                      style={{ cursor: "pointer" }}
                      onClick={this.handleButtonChange}
                    >
                      <span id="print">Print this Page</span>
                    </i>
                  </a>

                  {popUp}

                  <div style={{ display: "none" }}>
                    {" "}
                    <ComponentToPrint
                      ref={el => (this.componentRef = el)}
                      startDate={this.state.startDate}
                      servicesData={this.state.servicesData}
                      serviceDataPediatric={this.state.serviceDataPediatric}
                      servicesDataWard={this.state.servicesDataWard}
                      shrinerData={this.state.shrinerData}
                      currentView={this.state.currentView}
                    />
                  </div>
                </div>
              </div>
              <Tabs
                className="btn-group-md"
                id="btnTabGroup"
                defaultActiveKey={HOSPITAL_SERVICE}
                onSelect={k => this.servicecalled(k)}
              >
                <Tab
                  eventKey={HOSPITAL_SERVICE}
                  id="btnHouseServices"
                  title="Hospital Services"
                />
                <Tab
                  eventKey={PEDIATRIC_SERVICE}
                  id="btnPediatric"
                  title="Pediatric House Staff"
                />
                <Tab
                  eventKey={PEDIATRIC_WARD_SERVICE}
                  id="btnPediatricWard"
                  title="Pediatric House Staff Ward Attendings"
                />
                <Tab
                  eventKey={SHRINER_SERVICE}
                  id="btnShrinerService"
                  title="Shriner's Service"
                 />
              </Tabs>
              <div>
                <div className="container table-responsive" id="main">
                  {currentService}
                </div>
              </div>
            </div>
            {this.state.servicesData.length > 0 ||
            this.state.serviceDataPediatric.length > 0 ? (
              <footer
                style={{
                  width: "100%",
                  height: "50px",
                  backgroundColor: "#015a96",
                  display: "block"
                }}
              />
            ) : (
              <></>
            )}
          </div>
        );
      }
    }
  )

class ComponentToPrint extends React.Component {
  render() {
    return (
      <>
        {this.props.currentView === HOSPITAL_SERVICE ? (
          <div className="container">
            <h1 className="menuOptionHeading">
              Schedule for {this.props.startDate}
            </h1>
            <br />
            {this.props.servicesData.map((item, i) => {
              return (
                <>
                  <h5 style={{ color: "#00b4ef" }}>{item.serviceName}</h5>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Role</th>
                        <th>Level</th>
                        <th>Start</th>
                        <th>Stop</th>
                        <th>Provider</th>
                        <th>Contact 1</th>
                        <th>Contact 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.schedules.map((schedule, i) => {
                        return (
                          <tr key={i}>
                            <td>{schedule.role}</td>
                            <td>{schedule.level}</td>
                            <td>{schedule.start}</td>
                            <td>{schedule.stop}</td>
                            <td>{schedule.provider}</td>
                            <td>
                              {schedule.contact1} {schedule.contact1Type}
                            </td>
                            <td>
                              {schedule.contact2}
                              {schedule.contact2Type}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <p>{item.serviceNote}</p>
                </>
              );
            })}
          </div>
        ): this.props.currentView === PEDIATRIC_SERVICE ? (
          <div className="container">
            <h1 className="menuOptionHeading">
              Schedule for {this.props.startDate}
            </h1>
            <br />
            {this.props.serviceDataPediatric.map((item, i) => {
              return (
                <>
                  <h5 style={{ color: "#00b4ef" }}>{item.serviceName}</h5>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Role</th>
                        <th>Level</th>
                        <th>Start</th>
                        <th>Stop</th>
                        <th>Provider</th>
                        <th>Contact 1</th>
                        <th>Contact 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.schedules.map((schedule, i) => {
                        return (
                          <tr key={i}>
                            <td>{schedule.role}</td>
                            <td>{schedule.level}</td>
                            <td>{schedule.start}</td>
                            <td>{schedule.stop}</td>
                            <td>{schedule.provider}</td>
                            <td>
                              {schedule.contact1} {schedule.contact1Type}
                            </td>
                            <td>
                              {schedule.contact2}
                              {schedule.contact2Type}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <p>{item.serviceNote}</p>
                </>
              );
            })}
          </div>
        ) : (
          this.props.currentView === SHRINER_SERVICE ? (
            <div className="container">
            <h1 className="menuOptionHeading">
              Schedule for {this.props.startDate}
            </h1>
            <br />
            {this.props.shrinerData.map((item, i) => {
              return (
                <>
                  <h5 style={{ color: "#00b4ef" }}>{item.serviceName}</h5>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.schedules.map((schedule, i) => {
                        return (
                          <tr key={i}>
                          <td id="onCallListDate" dangerouslySetInnerHTML={{__html: schedule.description}} />
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              );
            })}
          </div>
          ) : (
            <div className="container">
            <h1 className="menuOptionHeading">
              Schedule for {this.props.startDate}
            </h1>
            <br />
            <table
              className="elementTable table bordered "
              style={{ borderCollapse: "collapse", width: "100%" }}
              size="sm"
              responsive="sm"
            >
              <thead>
                <tr>
                  <th>Block</th>
                  <th>Dates</th>
                  <th>Team 1</th>
                  <th>Team 2</th>
                  <th>Team 3</th>
                  <th>Team 4</th>
                  <th>Team 5</th>
                  <th>Team 6</th>
                  <th>Team 7</th>
                </tr>
              </thead>
              <tbody>
                {this.props.servicesDataWard.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.block}</td>

                      <td>
                        {item.startDate} {item.endDate}
                      </td>
                      <td>{item.team1}</td>
                      <td>{item.team2}</td>
                      <td>{item.team3}</td>
                      <td>{item.team4}</td>
                      <td>{item.team5}</td>
                      <td>{item.team6}</td>
                      <td>{item.team7}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          )
        )}
      </>
    );
  }
}


// shrinerData still to work