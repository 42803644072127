async function checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();

    const sessionExists = await this.props.auth._oktaAuth.session.exists()
    if(!sessionExists) {
        this.props.auth.logout('/')
    }
   
    if (authenticated !== this.state.authenticated) {
      if (authenticated && !this.state.userInfo) {
        const userInfo = await this.props.auth.getUser();
        if(userInfo === undefined) {
          this.props.auth.login('/')
        }
        this.setState({ authenticated, userInfo });
      } else {
        this.setState({ authenticated });
      }
    }
    
  }
  
  export { checkAuthentication };