import React, { Component } from "react";
import { withAuth } from "@okta/okta-react";
import { BrowserRouter,Route} from "react-router-dom";

import Nav from "../nav/nav";
import OnCallMain from "../onCallMain/onCallMain";
import { checkAuthentication } from "../helper/helper";

export default withAuth(
  class Home extends Component {
    constructor(props) {
      super(props);
      this.state = { authenticated: null, userInfo: null, validSession: false };
      this.checkAuthentication = checkAuthentication.bind(this);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    componentDidMount() {
      this.checkAuthentication();

    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    async login() {
      this.props.auth.login("/");
    }

    async logout() {
      this.props.auth.logout("/");
    }

    render() {
      if (this.state.authenticated === null) return null;
      if (this.state.authenticated) {
        return (
          <>
            <Nav logout={this.logout} userInfo={this.state.userInfo}  />
            <OnCallMain />
          </>
        );
      }
      if (!this.state.authenticated) {
        this.login();
        return <></>;
      }
      
    }
  }
);
