import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "babel-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as serviceWorker from "./serviceWorker";
ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
