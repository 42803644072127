import React, { Component } from "react";
import "./nav.scss";
import Navbar from "react-bootstrap/Navbar";
import { withAuth } from "@okta/okta-react";

import { checkAuthentication } from "../helper/helper";

export default withAuth(
  class Nav extends Component {
    logout = async () => {
      const { logout } = this.props;
      logout();
    };

    render() {
      return (
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand className="logo">
            <img src={require("../../assets/logoCHLA.png")} alt="Image" />
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          <>
            <div className=" w-100 order-3">
              <div className="d-md-flex d-block w-100">
                <div
                  className=" mx-auto w-auto justify-content-center"
                  id="navbarNavAltMarkup"
                >
                  <div>
                    <div id="deviceAssess">CHLA On-Call Schedule</div>
                  </div>
                </div>
                <div className="nav-menu" id="navMenu">
                  <div id="left">
                    <a>{this.props.userInfo.name}</a>
                    <span>|</span>
                  </div>

                  <div id="right">
                    <a id="nav_logout" onClick={this.logout}>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Navbar>
      );
    }
  }
);
