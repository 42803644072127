import React, { Component } from "react";
import "./hospitalServices.scss";
import OnCallList from "../onCallList/onCallList";

const HOSPITAL = "hospital";
class HospitalServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalServicesData: { item: [] },
      isExpanded: false,
    };
  }

  expandAll = (e) => {
    e.preventDefault();
    let clickState = JSON.parse(localStorage.getItem("clickState"));
    if(this.state.isExpanded === false) {
      if (clickState !== null) {
        if (HOSPITAL in clickState) {
          clickState[HOSPITAL] = {};
          localStorage.setItem("clickState", JSON.stringify(clickState));
      }
    }
    this.setState({ isExpanded: true }, () => {
      console.log(this.state.isExpanded);
    });
    } else {
          this.setState({ isExpanded: false }, () => {
            console.log(this.state.isExpanded);
          });
    }
  };

  render() {
    return (
      <div>
        <div className="text-left infoDetail">
          <br />A service listed in <b id="red">red</b> below indicates
          that the call schedule for that service may be incomplete or contains
          an error. Please click on the{" "}
          <a
            className="link_admin"
            target="_blank"
            href="https://chla.sharepoint.com/sites/HospitalMedicine/Lists/Contacts"
          >
            administrative assistant link
          </a>{" "}
          to find the appropriate contact information, for the specific service,
          to report a red tab or for any other problem you may find with the
          On-Call Schedule.
        </div>
        {this.props.extractDate !== undefined &&
        this.props.extractDate !== null ? (
          <>
            <div
              className="infoDetail"
              style={{ marginTop: "10px", position: "absolute" }}
            >
              Data as of {this.props.extractDate}
            </div>
            <div style={{ float: "right" }}>
              {this.state.isExpanded === false ? (
                <a
                  href="#"
                  onClick={e => this.expandAll(e)}
                  style={{ color: "#00b4ef", fontWeight: "bold" }}
                >
                  Expand All
                </a>
              ) : (
                <a
                  href="#"
                  onClick={e => this.expandAll(e)}
                  style={{ color: "#00b4ef", fontWeight: "bold" }}
                >
                  Collapse All
                </a>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="hospitalServicesTab">
          {this.props.hospitalServicesData.length > 0 ? (
            this.props.hospitalServicesData.map((item, index) => {
              return (
                <OnCallList
                  key={index}
                  index={index}
                  dept={item.serviceName}
                  list={item.schedules}
                  serviceNote={item.serviceNote}
                  hasError={item.hasError}
                  isExpanded={this.state.isExpanded}
                  view={HOSPITAL}
                />
              );
            })
          ) : (
            <>
              <br />
              <div style={{ color: "red" }}>No schedule found</div>
            </>
          )}
        </div>
        <div className="text-left infoDetail">
          <br />
          To view schedules prior to the last seven days, please contact your
          division scheduler.
        </div>
        <br />
      </div>
    );
  }
}

export default HospitalServices;
