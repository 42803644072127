let configuration = process.env.REACT_APP_API_ENDPOINT

// if(process.env.NODE_ENV === 'development') {
//       configuration = 'https://api-appdev-resourcedirectory-prod-amion.ase-eapps-prod-001.p.azurewebsites.net'
// }
// else if(process.env.NODE_ENV === 'production') {
// //      configuration = 'https://api-appdev-resourcedirectory-prod.ase-eapps-prod-001.p.azurewebsites.net'
//      configuration = 'https://api-appdev-resourcedirectory-prod-uat.ase-eapps-prod-001.p.azurewebsites.net'
// }
export {configuration}
