import React, { Component } from "react";
import OnCallList from '../onCallList/onCallList';

const SHRINER = 'shriner'
class ShrinerService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalServicesData: { item: [] },
      isShriners: true,
      isExpanded: false
    };
  }


  expandAll = (e) => {
    e.preventDefault();
    let clickState = JSON.parse(localStorage.getItem("clickState"));
    if(this.state.isExpanded === false) {
      if (clickState !== null) {
        if (SHRINER in clickState) {
          clickState[SHRINER] = {};
          localStorage.setItem("clickState", JSON.stringify(clickState));
        }
      }
      this.setState({ isExpanded: true }, () => {
        console.log(this.state.isExpanded);
      });
    } else {
          this.setState({ isExpanded: false }, () => {
            console.log(this.state.isExpanded);
          });
    }
  };


  render() {
    return (
      <div>
        <div className="text-left infoDetail">
        </div>
        <div style={{ float: "right", marginTop: '10px' }}>
              {this.state.isExpanded === false ? (
                <a
                  href="#"
                  onClick={e => this.expandAll(e)}
                  style={{ color: "#00b4ef", fontWeight: "bold" }}
                >
                  Expand All
                </a>
              ) : (
                <a
                  href="#"
                  onClick={e => this.expandAll(e)}
                  style={{ color: "#00b4ef", fontWeight: "bold" }}
                >
                  Collapse All
                </a>
              )}
            </div>
        <div className="hospitalServicesTab">
          {this.props.shrinerServiceData.length > 0 ? (
            this.props.shrinerServiceData.map((item, index) => {
              return (
                <OnCallList
                  key={index}
                  index={index}
                  dept={item.serviceName}
                  list={item.schedules}
                  shrinerservice={this.state.isShriners}
                  isExpanded={this.state.isExpanded}
                  view= {SHRINER}
                />
                
              );
            })
          ) : (
            <>
              <br />
              <div style={{ color: "red" }}>No schedule found</div>
            </>
          )}
        </div>
        <div className="text-left infoDetail">
          <br />
        </div>
      </div>
    );
  }
}

export default ShrinerService;
