import React, { Component } from "react";
import "../hospitalServices/hospitalServices.scss";
import OnCallList from "../onCallList/onCallList";

const PEDIATRIC = "pediatric";
let isTimeNote = false;
let isDayNote = false;
const date = new Date();
const hour = date.getHours();
const day = date.getDay();

class PediatricServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pediatricServicesData: [],
      isExpanded: false,
    };
  }

  expandAll = (e) => {
    e.preventDefault();
    let clickState = JSON.parse(localStorage.getItem("clickState"));
    if (this.state.isExpanded === false) {
      if (clickState !== null) {
        if (PEDIATRIC in clickState) {
          clickState[PEDIATRIC] = {};
          localStorage.setItem("clickState", JSON.stringify(clickState));
        }
      }
      this.setState({ isExpanded: true }, () => {
        console.log(this.state.isExpanded);
      });
    } else {
      this.setState({ isExpanded: false }, () => {
        console.log(this.state.isExpanded);
      });
    }
  };

  

  render() {
    let pediatricServicesData = this.props.pediatricServicesData;
    if(day == 0 || day == 6) {
      isDayNote = false;
    }
    else {
      isDayNote = true;
    }
    if( (hour >= 12 && hour <= 13)) {
      isTimeNote = true;
    }
    else {
      isTimeNote = false;
    }

    return (
      <div>
      {isDayNote && isTimeNote ? <div className="text-left infoDetail" id="red">
      <br />
      During the protected resident teaching hour from
      12-1pm Monday through Friday, please contact the
      attending directly for all questions and sign-outs.
      The general pediatrics attendings are listed under
      the "Pediatric House Staff Ward Attending" tab and
      subspecialty attendings are listed under the
      "Hospital Services" tab by specialty.
    </div> : <></> }
        
        <div className="text-left infoDetail">
          <br />A service listed in <b className="red">red</b> below indicates
          that the call schedule for that service may be incomplete or contains
          an error. Please contact the administrative assistant for that
          specific service to report a red tab or any other problem you may find
          with the On-Call Schedule. The CHLA switchboard operators (x12403 or
          '0') may provide you with the administrative assistant's name, if
          needed.
        </div>
        {this.props.extractDate !== undefined &&
        this.props.extractDate !== null ? (
          <>
            <div className="infoDetail" style={{ marginTop: "10px" }}>
              Data as of {this.props.extractDate}
            </div>
            <div style={{ float: "right" }}>
              {this.state.isExpanded === false ? (
                <a
                  href="#"
                  onClick={(e) => this.expandAll(e)}
                  style={{ color: "#00b4ef", fontWeight: "bold" }}
                >
                  Expand All
                </a>
              ) : (
                <a
                  href="#"
                  onClick={(e) => this.expandAll(e)}
                  style={{ color: "#00b4ef", fontWeight: "bold" }}
                >
                  Collapse All
                </a>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="hospitalServicesTab">
          {this.props.pediatricServicesData.length > 0 ? (
            pediatricServicesData.map((item, index) => {
              return (
                <OnCallList
                  key={index}
                  index={index}
                  dept={item.serviceName}
                  list={item.schedules}
                  serviceNote={item.serviceNote}
                  hasError={item.hasError}
                  view={PEDIATRIC}
                  isExpanded={this.state.isExpanded}
                  isTimeNote={isTimeNote}
                  isDayNote={isDayNote}
                />
              );
            })
          ) : (
            <>
              <br />
              <div style={{ color: "red" }}>No schedule found</div>
            </>
          )}
        </div>
        <div className="text-left infoDetail">
          <br />
          To view schedules prior to the last seven days, please contact your
          division scheduler.
        </div>
        <br />
      </div>
    );
  }
}

export default PediatricServices;
