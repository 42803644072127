import React, { Component } from "react";
import { Table, Button, Collapse } from "react-bootstrap";
// import "../hospitalServices/hospitalServices.scss";
import "./onCallList.css";

class OnCallList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    if (this.props.isExpanded === false) {
      let clickState = JSON.parse(localStorage.getItem("clickState"));
      if (clickState !== null && this.props.view in clickState) {
        this.setState({ open: clickState[this.props.view][this.props.index] });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isExpanded) {
      this.setState({ open: true });
    } else {
      let clickState = JSON.parse(localStorage.getItem("clickState"));
      if (clickState !== null && nextProps.view in clickState) {
        // this.setState({open: clickState[this.props.index]})
        this.setState({ open: clickState[nextProps.view][nextProps.index] });
      } else if (nextProps.isExpanded === false) {
        this.setState({ open: false });
      }
    }
  }

  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  containsNumber = (str) => {
    return /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
      str
    );
  };

  collapseDropedown = () => {
    let view = this.props.view;
    let index = this.props.index;
    this.setState({ open: !this.state.open }, () => {
      let clickState = JSON.parse(localStorage.getItem("clickState"));
      if (clickState === null) {
        clickState = {};
        clickState[view] = {};
      }
      if (!(view in clickState)) {
        clickState[view] = {};
      }
      clickState[view][this.props.index] = this.state.open;
      // clickState[this.props.index] = this.state.open;
      localStorage.setItem("clickState", JSON.stringify(clickState));
    });
  };

  render() {
    const { dept, list, serviceNote, hasError, shrinerservice } = this.props;
    const { open } = this.state;
    let serviceNoteElement;
    let errorSpan,
      checkError = false;
    if (dept === "Spiritual Care") {
      errorSpan = <></>;
      checkError = false;
    } else if (list.length <= 0 || list.length === null) {
      errorSpan = (
        <div className="errorSpan">
          (On-call care providers have not been entered for this service.)
        </div>
      );
      checkError = true;
    } else if (hasError === true) {
      errorSpan = (
        <div className="errorSpan">
          (Some care providers in this service have no contact information.)
        </div>
      );
      checkError = true;
    } else {
      errorSpan = <></>;
      checkError = false;
    }
    if (serviceNote !== "" && serviceNote !== null) {
      if (this.validURL(serviceNote)) {
        if (dept === "Radiology") {
          serviceNoteElement = (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00b4ef" }}
              href={serviceNote}
            >
              Radiology Daily Schedule
            </a>
          );
        } else {
          serviceNoteElement = (
            <a style={{ color: "#00b4ef" }} href={serviceNote}>
              {serviceNote}
            </a>
          );
        }
      } else {
        serviceNoteElement = <div>{serviceNote}</div>;
      }
    }

    let error = hasError === true;
    

    return (
      <>
        <div className="hospitalServices">
          <Button
            onClick={this.collapseDropedown}
            aria-controls="menuDropdown"
            aria-expanded={open}
            className={
              checkError
                ? "btn-lg btnHospitalService buttonError"
                : "btn-lg btnHospitalService"
            }
            id="dropdown-basic"
            style={{
              border:
                dept === "Spiritual Care"
                  ? "none"
                  : error || list.length <= 0 || list.length === null
                  ? "1px solid #cc0000"
                  : "none",
            }}
          >
            <span className="name" id={this.props.className}>
              {dept}
            </span>
            {errorSpan}

            {this.state.open ? (
              <i className="fa fa-minus plusSign" />
            ) : (
              <i className="fa fa-plus plusSign" />
            )}
          </Button>
          {(list.length <= 0 || list.length === null) &&
          serviceNote === null ? (
            <></>
          ) : (
            <Collapse in={this.state.open} className="btn-lg ">
              <div className="schedTable" id="menuDropdown">
                <Table
                  striped
                  hover
                  className="elementTable striped "
                  bordered
                  size="sm"
                  responsive="sm"
                  id={this.props.id}
                >
                  {list.length <= 0 || list.length === null ? (
                    <></>
                  ) : this.props.shrinerservice === true ? (
                    <>
                      <thead>
                        <tr className="tr_list">
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => {
                          return (
                            <tr className="tr_list" key={index}>
                              <td
                                id="onCallListDate"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <thead>
                        <tr className="tr_list">
                          <th>Role</th>
                          <th>Level</th>
                          <th>Start</th>
                          <th>Stop</th>
                          <th>Provider</th>
                          <th>Contact 1</th>
                          <th>Contact 2</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => {
                          return (
                            <>
                              <tr className="tr_list" key={index}>
                                <td id="onCallListDate">{item.role}</td>
                                <td id="onCallListDate">{item.level}</td>
                                <td id="onCallListDate">{item.start}</td>
                                <td id="onCallListDate">{item.stop}</td>
                                <td id="onCallListDate">{item.provider}</td>
                                <td id="onCallListDate">
                                  {dept === "Radiology" &&
                                  (item.contact1.includes("(URL below)") ||
                                    item.contact1.includes(
                                      "Radiology Schedule"
                                    )) ? (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="contact_1"
                                      href={serviceNote}
                                    >
                                      {item.contact1}
                                    </a>
                                  ) : (
                                    <>
                                      {this.containsNumber(item.contact1) ===
                                      true ? (
                                        <>
                                          {item.contact1Type !== null ? (
                                            item.contact1Type.includes(
                                              "Pager"
                                            ) ? (
                                              <>{item.contact1}</>
                                            ) : item.contact1Type.includes(
                                                "@"
                                              ) ? (
                                              <>
                                                {" "}
                                                <a
                                                  className="contact_1"
                                                  href={
                                                    "mailto:" + item.contact1
                                                  }
                                                >
                                                  {item.contact1}
                                                </a>
                                              </>
                                            ) : item.contact1Type.includes(
                                                "Vocera"
                                              ) ? (
                                              <a
                                                className="contact_1"
                                                target="_blank"
                                                href="https://prod.pss.chla.usc.edu:8443/msg/"
                                              >
                                                {item.contact1}
                                              </a>
                                            ) : (
                                              <>
                                                {" "}
                                                <a
                                                  className="contact_1"
                                                  href={"tel:" + item.contact1}
                                                >
                                                  {item.contact1}
                                                </a>
                                              </>
                                            )
                                          ) : (
                                            <>{item.contact1}</>
                                          )}
                                        </>
                                      ) : item.contact1Type !== null ? (
                                        item.contact1Type.includes("Vocera") ? (
                                          <a
                                            className="contact_1"
                                            target="_blank"
                                            href="https://prod.pss.chla.usc.edu:8443/msg/"
                                          >
                                            {item.contact1}
                                          </a>
                                        ) : (
                                          <>{item.contact1}</>
                                        )
                                      ) : item.contact1.includes("@") ? (
                                        <>
                                          {" "}
                                          <a
                                            className="contact_1"
                                            href={"mailto:" + item.contact1}
                                          >
                                            {item.contact1}
                                          </a>
                                        </>
                                      ) : (
                                        <>{item.contact1}</>
                                      )}
                                    </>
                                  )}

                                  {item.contact1Type !== null ? (
                                    item.contact1Type.includes("Vocera") ? (
                                      <a
                                        className="contact_1"
                                        target="_blank"
                                        href="https://prod.pss.chla.usc.edu:8443/msg/"
                                      >
                                        {" "}
                                        {item.contact1Type}
                                      </a>
                                    ) : (
                                      <> {item.contact1Type}</>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td id="onCallListDate">
                                  {this.containsNumber(item.contact2) ===
                                  true ? (
                                    <>
                                      {item.contact2Type !== null ? (
                                        item.contact2Type.includes("Pager") ? (
                                          <>{item.contact2}</>
                                        ) : item.contact2Type.includes("@") ? (
                                          <>
                                            {" "}
                                            <a
                                              className="contact_1"
                                              href={"mailto:" + item.contact2}
                                            >
                                              {item.contact2}
                                            </a>
                                          </>
                                        ) : item.contact2Type.includes(
                                            "Vocera"
                                          ) ? (
                                          <a
                                            className="contact_1"
                                            target="_blank"
                                            href="https://prod.pss.chla.usc.edu:8443/msg/"
                                          >
                                            {item.contact2}
                                          </a>
                                        ) : (
                                          <>
                                            {" "}
                                            <a
                                              className="contact_1"
                                              href={"tel:" + item.contact2}
                                            >
                                              {item.contact2}
                                            </a>
                                          </>
                                        )
                                      ) : item.contact2.includes("@") ? (
                                        <>
                                          {" "}
                                          <a
                                            className="contact_1"
                                            href={"mailto:" + item.contact2}
                                          >
                                            {item.contact2}
                                          </a>
                                        </>
                                      ) : (
                                        <>{item.contact2}</>
                                      )}
                                    </>
                                  ) : item.contact2Type !== null ? (
                                    item.contact2Type.includes("Vocera") ? (
                                      <a
                                        className="contact_1"
                                        target="_blank"
                                        href="https://prod.pss.chla.usc.edu:8443/msg/"
                                      >
                                        {item.contact2}
                                      </a>
                                    ) : (
                                      <>{item.contact2}</>
                                    )
                                  ) : item.contact2.includes("@") ? (
                                    <>
                                      {" "}
                                      <a
                                        className="contact_1"
                                        href={"mailto:" + item.contact2}
                                      >
                                        {item.contact2}
                                      </a>
                                    </>
                                  ) : (
                                    <>{item.contact2}</>
                                  )}

                                  {item.contact2Type !== null ? (
                                    item.contact2Type.includes("Vocera") ? (
                                      <a
                                        className="contact_1"
                                        target="_blank"
                                        href="https://prod.pss.chla.usc.edu:8443/msg/"
                                      >
                                        {" "}
                                        {item.contact2Type}
                                      </a>
                                    ) : (
                                      <> {item.contact2Type}</>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>

                     
                    </>
                    
                  )}
                </Table>
                {this.props.view == "pediatric" && dept !== "Chief On Call" ? (
                  this.props.isDayNote && this.props.isTimeNote ? (
                    <div className="service_note" id="red">
                      During the protected resident teaching hour from
                      12-1pm Monday through Friday, please contact the
                      attending directly for all questions and sign-outs.
                      The general pediatrics attendings are listed under
                      the "Pediatric House Staff Ward Attending" tab and
                      subspecialty attendings are listed under the
                      "Hospital Services" tab by specialty.
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                <div className="service_note">{serviceNoteElement}</div>
              </div>
            </Collapse>
          )}
        </div>
      </>
    );
  }
}

export default OnCallList;
