import React, { Component } from "react";
import { ImplicitCallback, Security, SecureRoute } from "@okta/okta-react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./Components/home/Home";

console.log(window.location.origin)
class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Security
            // dev slot
            issuer="https://oktalogin.chla.org/oauth2/aus77svjdiCvaf9cC2p7"
            clientId="0oa77t1fqbOI9zYJ32p7"

            //prod cred
            // issuer="https://chla.okta.com/oauth2/aus51wgp73YceE2Rc2p7"
            // clientId="0oa59pb196ZcYtHlg2p7"

            // preview creds for deploying to preview slot
            // issuer="https://chla.oktapreview.com/oauth2/ausmfj33bf8BQKLCF0h7"
            // clientId="0oane014w1GpwEf5J0h7"

            // oktapreview
            // issuer="https://oktaloginpreview.chla.org/oauth2/ausrmebmovfqx6cGp0h7"
            // clientId="0oarmb6jbqi56nRup0h7"
            redirectUri={window.location.origin + "/implicit/callback"}
          >
            <Route path="/implicit/callback" component={ImplicitCallback} />

            <SecureRoute path="/" component={Home} />
          </Security>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
