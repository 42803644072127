import React, { Component } from "react";
import "../hospitalServices/hospitalServices.scss";
// import OnCallStaffList from "../onCallStaffList/onCallStaffList";
import { Table } from "react-bootstrap";
import "./pediatricServiceWard.css";
let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yy = today.getFullYear();
today = mm + "/" + dd + "/" + yy;
class PediatricServicesWard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pediatricWardServicesData: []
    };
  }

  render() {
    let pediatricWardServicesData = this.props.pediatricServicesWardData;
    return (
      <div>
      {this.props.pediatricServicesWardData.length > 0 ? (
        <>
        <div>
          <div className="rectangle rec_pediatrics"></div>
          <div className="rectangle_name_pediatrics">
            Division of General Pediatrics
          </div>
          <div className="rectangle rec_medicine"></div>
          <div className="rectangle_name_medicine">
            Division of Hospital Medicine
          </div>
        </div>
        <br />
        <div
          className="hospitalServicesTab "
          id="pediatricWard"
          style={{ marginTop: "5%" }}
        >
          <Table
            className="elementTable table "
            style={{ borderCollapse: "collapse", width: "100%" }}
            bordered
            size="sm"
            responsive="sm"
          >
            <thead>
              <tr>
                <th>Block</th>
                <th>Dates</th>
                <th>Team 1</th>
                <th>Team 2</th>
                <th>Team 3</th>
                <th>Team 4</th>
                <th>Team 5</th>
                <th>Team 6</th>
                <th>Team 7</th>
              </tr>
            </thead>
            <tbody>
              {pediatricWardServicesData.map((item,index) => {
                let dateFrom = item.startDate.split(" ")[0];
                let dateTo = item.endDate.split(" ")[0];
                let checking = today >= dateFrom && today <= dateTo;
                return (
                  <tr
                    key={index}
                    style={{
                      fontSize: "80%",
                      border: checking ? "3px solid #F26D21" : "none"
                    }}
                  >
                    <td id="ward_service" style={{ verticalAlign: "middle" }}>
                      {item.block}
                    </td>

                    <td id="ward_service" style={{ verticalAlign: "middle" }}>
                      {item.startDate} {item.endDate}
                    </td>
                   
                      <td
                      id="ward_service"
                      style={{
                        verticalAlign: "middle",
                        backgroundColor:
                          item.team1Division === "Hospital Medicine"
                            ? "#d3edff"
                            : "white"
                      }}
                    >
                    {(item.team1 !== null && Array.isArray(item.team1)) ? ((item.team1).map(team1 => <div>{team1}</div>)) : item.team1}
                    </td>
                    
                    
                    <td
                      id="ward_service"
                      style={{
                        verticalAlign: "middle",
                        backgroundColor:
                          item.team2Division === "Hospital Medicine"
                            ? "#d3edff"
                            : "white"
                      }}
                    >
                    {(item.team2 !== null && Array.isArray(item.team2)) ? ((item.team2).map(team2 => <div>{team2}</div>)) : item.team2}

                    </td>
                    <td
                      id="ward_service"
                      style={{
                        verticalAlign: "middle",
                        backgroundColor:
                          item.team3Division === "Hospital Medicine"
                            ? "#d3edff"
                            : "white"
                      }}
                    >
                    {(item.team3 !== null && Array.isArray(item.team3)) ? ((item.team3).map(team3 => <div>{team3}</div>)) : item.team3}
                    
                    </td>
                    <td
                      id="ward_service"
                      style={{
                        verticalAlign: "middle",
                        backgroundColor:
                          item.team4Division === "Hospital Medicine"
                            ? "#d3edff"
                            : "white"
                      }}
                    >
                    {(item.team4 !== null && Array.isArray(item.team4)) ? ((item.team4).map(team4 => <div>{team4}</div>)) : item.team4}

                    </td>
                    <td
                      id="ward_service"
                      style={{
                        verticalAlign: "middle",
                        backgroundColor:
                          item.team5Division === "Hospital Medicine"
                            ? "#d3edff"
                            : "white"
                      }}
                    >
                    {(item.team5 !== null && Array.isArray(item.team5)) ? ((item.team5).map(team5 => <div>{team5}</div>)) : item.team5}

                    </td>
                    <td
                      id="ward_service"
                      style={{
                        verticalAlign: "middle",
                        backgroundColor:
                          item.team6Division === "Hospital Medicine"
                            ? "#d3edff"
                            : "white"
                      }}
                    >
                    {(item.team6 !== null && Array.isArray(item.team6)) ? ((item.team6).map(team6 => <div>{team6}</div>)) : item.team6}

                    </td>
                    <td
                      id="ward_service"
                      style={{
                        verticalAlign: "middle",
                        backgroundColor:
                          item.team7Division === "Hospital Medicine"
                            ? "#d3edff"
                            : "white"
                      }}
                    >
                    {(item.team7 !== null && Array.isArray(item.team7)) ? ((item.team7).map(team7 => <div>{team7}</div>)) : item.team7}

                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <br />
        </>
      ) : <>
      <br/>
            <div style={{color:'red'}}>No schedule found</div>
      </>}
        
      </div>
    );
  }
}

export default PediatricServicesWard;
